import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserListAPI, lockUserAPI, createUserAPI, adminUpdateUserAPI } from 'APIs/user'; // eslint-disable-line
import { getFileURLAPI, uploadFileWithURLAPI, adminGetFileURLAPI } from 'APIs/file';

// ==============================|| REDUX STORE: USER DATA ||============================== //

const GET_USER_LIST = createAsyncThunk('user/list', async () => {
    console.log('calling GET_USER_LIST');
    const response = await getUserListAPI();
    console.log('===> GET_USER_LIST response', response);
    return response;
});

const USER_LOCK = createAsyncThunk('user/lock', async ({ id }) => {
    console.log('calling USER_LOCK');
    const response = await lockUserAPI({ id });
    return response;
});

// const UPDATE_USER = createAsyncThunk('user/update', async ({ id, data = {}, file }) => {
//     // First Upload photo if necessary
//     if (file) {
//         const uploadURL = await getFileURLAPI({ fileName: file.name, path: 'userPhoto', mimeType: file.type });
//         data.photoURL = uploadURL.url.split('?')[0];
//         await uploadFileWithURLAPI({ url: uploadURL.url, file });
//     }
//     // Now update User data
//     const response = await updateUserAPI({ id, data });
//     return response;
// });

const ADMIN_UPDATE_USER = createAsyncThunk('user/update/admin', async ({ id, data = {}, file, orgID }) => {
    if (!id || !orgID) {
        throw new Error('Missing id or orgID');
    }
    // First Upload photo if necessary
    if (file) {
        const uploadURL = await adminGetFileURLAPI({ fileName: file.name, path: 'userPhoto', mimeType: file.type, orgID });
        data.photoURL = uploadURL.url.split('?')[0];
        await uploadFileWithURLAPI({ url: uploadURL.url, file });
    }
    // Now update User data
    const response = await adminUpdateUserAPI({ id, data });
    return response;
});

const CREATE_USER = createAsyncThunk('user/create', async ({ data }) => {
    const response = await createUserAPI({ data });
    return response;
});

const initialState = {
    initialized: false,
    users: [],
    client: {}
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        create(state, action) {
            state.users.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.users = [];
            state.client = [];
        },
        setIsFetching(state) {
            state.state.isFetching = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(CREATE_USER.fulfilled, (state, action) => {
                if (action.payload) {
                    state.users.push(action.payload);
                }
            })
            .addCase(ADMIN_UPDATE_USER.fulfilled, (state, action) => {
                const updatedUser = action.payload.user;
                if (updatedUser) {
                    console.log('updatedUser', updatedUser);
                    state.users = state.users.map((user) => {
                        if (user.id === updatedUser.id) {
                            return { ...user, ...updatedUser };
                        }
                        return user;
                    });
                }
            })
            // .addCase(UPDATE_USER.fulfilled, (state, action) => {
            //     state.users = state.users.map((user) => {
            //         if (user.id === action.payload.id) {
            //             return { ...user, ...action.payload };
            //         }
            //         return user;
            //     });
            // })
            .addCase(USER_LOCK.fulfilled, (state, action) => {
                state.users = state.users.map((user) => {
                    if (user.id === action.payload.id) {
                        return { ...user, ...action.payload };
                    }
                    return user;
                });
            })
            // .addCase(deleteAward.fulfilled, (state, action) => {
            //     const deletedID = action.meta.arg.id;
            //     if (deletedID) {
            //         state.users = state.users.filter((item) => item.id !== deletedID);
            //     }
            // })
            .addCase(GET_USER_LIST.fulfilled, (state, action) => {
                state.users = [...action.payload];
                state.initialized = true;
            });
    }
});

const { create, logout, setIsFetching } = userSlice.actions;
const initialized = (state) => state?.user?.initialized;
const users = (state) => state?.user?.users;

export { GET_USER_LIST, USER_LOCK, CREATE_USER, ADMIN_UPDATE_USER, initialized, users, create, logout, setIsFetching };
export default userSlice.reducer;
