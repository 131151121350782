import axios from 'axios';
// import { useDispatch, useSelector } from 'react-redux';

import { AWS_API_BASE_URL, API_BASE_URL } from 'config';
import ResponseStatus from 'models/common/ResponseStatus.tsx';
// import { USER_MESSAGE } from 'store/slices/statusSlice';

const apiClient = axios.create({
    baseURL: AWS_API_BASE_URL,
    headers: {
        accept: 'application/json'
    }
});

const djangoApiClient = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        accept: 'application/json, text/html, application/javascript'
    }
});

const apiDjangoDelete = async ({ url }) => {
    console.log('api Django Delete >>>>', url);
    const response = await djangoApiClient.delete(url);
    console.log('===> RESPONSE api Django Delete >>>>', response);
    return response.data;
};

const apiGet = async ({ url }) => {
    console.log('apiGet>>>>', url);
    const response = await apiClient.get(url);
    console.log('===> RESPONSE ', response);
    return response.data;
};

const apiDjangoGet = async ({ url, params }) => {
    console.log('apiDjangoGet >>>>', url, params);
    const response = await djangoApiClient.get(url, { params });
    return response.data;
};

const apiPost = async ({ url, data }) => {
    console.log('apiPost>>>>', url, data);
    try {
        const response = await apiClient.post(url, data);
        // console.log(response);
        return response.data;
    } catch (error) {
        // We have alreadty dealt with any errore in the interceptor
        console.log('API Post Error: ', error.message);
    }
    return null;
};

const apiPostGLReport = async (url, data, startDate, endDate, locationId, clientName) => {
    try {
        const response = await djangoApiClient.post(
            url,
            {
                location_id: locationId,
                start_date: startDate,
                end_date: endDate,
                report_json: data,
                name: clientName
            },
            {
                responseType: 'blob'
            }
        );

        return new Blob([response.data], { type: 'application/pdf' });
    } catch (error) {
        console.error('Error generating PDF:', error);
        throw error; // Rethrow the error so it can be caught in the calling function
    }
};

const apiPostDjango = async ({ url, data, params }) => {
    const response = await djangoApiClient.post(url, data, { params });
    // If response is ok (2XX), just return the data
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    // Otherwise, return the whole response object
    return response;
    // } catch (error) {
    //     // We have alreadty dealt with any errors in the interceptor
    //     const errorMessage = error?.response?.data?.errorMessage || error?.response?.data?.error || error.message;
    //     console.log('API Post Error: ', errorMessage);
    //     if (error?.response?.status && error?.response?.status < 500) {
    //         // dispatch(USER_MESSAGE({ message: errorMessage, alertSeverity: 'error' }));
    //         throw error;
    //     }
    //     // dispatch(USER_MESSAGE({ message: errorMessage, alertSeverity: 'error' }));
    // }
    // return null;
};
const apiPostPdfDjango = async ({ url, data, params }) => {
    const response = await djangoApiClient.post(url, data, { params, responseType: 'blob' });
    console.log(response);
    // If response is ok (2XX), just return the data
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    // Otherwise, return the whole response object
    return response;
    // } catch (error) {
    //     // We have alreadty dealt with any errors in the interceptor
    //     const errorMessage = error?.response?.data?.errorMessage || error?.response?.data?.error || error.message;
    //     console.log('API Post Error: ', errorMessage);
    //     if (error?.response?.status && error?.response?.status < 500) {
    //         // dispatch(USER_MESSAGE({ message: errorMessage, alertSeverity: 'error' }));
    //         throw error;
    //     }
    //     // dispatch(USER_MESSAGE({ message: errorMessage, alertSeverity: 'error' }));
    // }
    // return null;
};

const awsApiUpdate = async ({ url, data }) => {
    console.log('awsApi Patch >>>>');
    try {
        const response = await apiClient.patch(url, data);
        console.log(response);
        return response.data;
    } catch (error) {
        // We have alreadty dealt with any errore in the interceptor
        console.log('AWS API Patch Error: ', error.message);
    }
    return null;
};

const apiUpdate = async ({ url, data }) => {
    console.log('api PATCH >>>>');
    try {
        const response = await djangoApiClient.patch(url, data);
        console.log(response);
        return response.data;
    } catch (error) {
        // We have alreadty dealt with any errore in the interceptor
        console.log('API Patch Error: ', error.message);
    }
    return null;
};

const apiDeleteOld = async ({ url }) => {
    console.log('api DELETE >>>>');
    try {
        const response = await djangoApiClient.delete(url);
        console.log(response);
        return response.data;
    } catch (error) {
        // We have already dealt with any errors in the interceptor
        console.log('API Delete Error: ', error.message);
    }
    return null;
};

const apiDelete = async ({ url, data }) => {
    console.log('api DELETE >>>>');
    const responseStatus = new ResponseStatus();
    try {
        const response = await djangoApiClient.delete(url, { data });
        return response.data;
    } catch (error) {
        // We have already dealt with any errors in the interceptor
        // console.error('API Delete Error: ', error);
        responseStatus.status = error.response.status;
        responseStatus.message = error.response.message;
        responseStatus.statusText = error.response.statusText;
        responseStatus.data = error.response.data;
    }
    // console.log("responseStatus:",responseStatus)
    return responseStatus;
};

const apiFileUpload = async ({ url, file }) => {
    console.log('apiFileUpload>>>>');
    const headers = { 'Content-Type': file.type };
    const response = await axios.put(url, file, { headers });
    console.log(response);
    return response.data;
};
const deleteGroupAccount = async ({ url, data }) => {
    console.log('api DELETE >>>>');
    try {
        const response = await djangoApiClient.delete(url, { data });
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const apiPostFileDjango = async ({ url, data }) => {
    console.log('apiPost>>>> ', url, data);
    const instance = djangoApiClient;
    instance.interceptors.request.use((config) => {
        config.headers['Content-Type'] = 'multipart/form-data';
        return config;
    });
    const response = await instance.post(url, data);
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    return response;
};

export default apiClient;
export {
    apiGet,
    awsApiUpdate,
    apiPost,
    apiDjangoDelete,
    apiFileUpload,
    apiPostDjango,
    djangoApiClient,
    apiPostGLReport,
    apiDjangoGet,
    apiUpdate,
    apiDelete,
    deleteGroupAccount,
    apiPostFileDjango,
    apiPostPdfDjango
};
