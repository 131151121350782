import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Drawer,
    Button,
    Stack,
    Grid,
    useMediaQuery,
    Link,
    Typography,
    ListItem,
    List,
    ListItemIcon,
    LinearProgress,
    ListItemText
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MenuList from './MenuList';
import { openDrawer } from 'store/slices/menu';
import { drawerWidth } from 'themes/constant';
import Chip from 'ui-component/extended/Chip';
import { ONBOARDING_HOME_PATH } from 'config';
import Logo from 'ui-component/Logo';
import { IconCheckbox, IconSquare, IconSquareCheck } from '@tabler/icons';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ window }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const clientData = useSelector((state) => state?.account?.userData?.client);
    const onboardingAccountInfoComplete = clientData?.onboarding_status?.account_info_stage_completed;
    const onboardingOperationsComplete = clientData?.onboarding_status?.completed;
    const onboardingTask3Complete = useSelector((state) => state.account?.user?.onboardingTask3Complete);

    const logo = useMemo(
        () => (
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <Link component={RouterLink} to={ONBOARDING_HOME_PATH}>
                        <Logo />
                    </Link>
                </Box>
            </Box>
        ),
        []
    );

    const drawer2 = ({
        matchUpMd,
        onboardingAccountInfoComplete,
        onboardingOperationsComplete,
        onboardingTask3Complete,
        onboardingTask4Complete
    }) => {
        const onboardingPctComplete = 10;
        // const memoizedDrawer = useMemo(() => {

        return (
            <PerfectScrollbar
                component="div"
                style={{
                    height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                    paddingLeft: '16px',
                    paddingRight: '16px'
                }}
            >
                <Grid container sx={{ px: 2, mt: 2 }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" color="primary">
                            Onboarding Tasks
                        </Typography>
                    </Grid>
                    <Grid item>
                        <List dense>
                            <ListItem>
                                <ListItemIcon sx={{ color: onboardingAccountInfoComplete ? 'success.dark' : '' }}>
                                    {onboardingAccountInfoComplete && <IconCheckbox />}
                                    {!onboardingAccountInfoComplete && <IconSquare />}
                                </ListItemIcon>
                                <ListItemText primary="Account Info" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ color: onboardingOperationsComplete ? 'success.dark' : '' }}>
                                    {onboardingOperationsComplete && <IconCheckbox />}
                                    {!onboardingOperationsComplete && <IconSquare />}
                                </ListItemIcon>
                                <ListItemText primary="Operations" />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </PerfectScrollbar>
        );
    };

    const drawer = useMemo(
        () => (
            <PerfectScrollbar
                component="div"
                style={{
                    height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                    paddingLeft: '16px',
                    paddingRight: '16px'
                }}
            >
                {/* <Grid container sx={{ px: 2, mt: 2 }}>
                    <Grid item xs={12}>
                        <Typography variant="h4" color="primary">
                            Onboarding Tasksf
                        </Typography>
                    </Grid>
                    <Grid item>
                        <List dense>
                            <ListItem>
                                <ListItemIcon sx={{ color: onboardingAccountInfoComplete ? 'success.dark' : '' }}>
                                    {onboardingAccountInfoComplete && <IconCheckbox />}
                                    {!onboardingAccountInfoComplete && <IconSquare />}
                                </ListItemIcon>
                                <ListItemText primary="Account Info" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon sx={{ color: onboardingOperationsComplete ? 'success.dark' : '' }}>
                                    {onboardingOperationsComplete && <IconCheckbox />}
                                    {!onboardingOperationsComplete && <IconSquare />}
                                </ListItemIcon>
                                <ListItemText primary="Operations" />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid> */}
            </PerfectScrollbar>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [matchUpMd, onboardingAccountInfoComplete, onboardingOperationsComplete, onboardingTask3Complete]
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={() => dispatch(openDrawer(!drawerOpen))}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '88px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawerOpen && logo}
                {drawerOpen && drawer}
            </Drawer>
        </Box>
    );
};

Sidebar.propTypes = {
    window: PropTypes.object
};

export default memo(Sidebar);
