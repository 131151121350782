const LOCAL_STORE = 'fixeData';

const loadState = () => {
    try {
        const serializedState = localStorage.getItem(LOCAL_STORE);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

const saveState = ({ state }) => {
    try {
        const saveState = { ...state };
        delete saveState.status; // Don't persist status slice
        const serializedState = JSON.stringify(saveState);
        localStorage.setItem(LOCAL_STORE, serializedState);
    } catch (err) {
        console.log(err);
    }
};

export { loadState, saveState };
