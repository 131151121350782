import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from 'hooks/useAuth';

const ProtectedRoute = ({ children }) => {
    const user = useSelector((state) => state.user);
    const { isLoggedIn, userType, onboardComplete } = useAuth();
    const location = useLocation();
    const navigate = useNavigate(); // Added useNavigate hook
    const clientData = useSelector((state) => state?.account?.userData?.client);
    // const onboardingComplete = clientData?.onboarding_status?.account_info_stage_completed && clientData?.onboarding_status?.completed;

    if (userType === 'am') {
        navigate('/am', { state: { from: location }, replace: true }); // Changed to navigate function
        return null;
    }

    if (userType === 'admin') {
        navigate('/admin', { state: { from: location }, replace: true }); // Changed to navigate function
        return null;
    }

    /*
    if (onboardingComplete) {
        navigate('/client/reports', { state: { from: location }, replace: true }); // Changed to navigate function
        return null;
    }
        */

    return children;
};

export default ProtectedRoute;
