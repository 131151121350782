import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { Alert, Backdrop, CircularProgress, Fade, Grid, IconButton, Typography } from '@mui/material';
import MuiSnackbar from '@mui/material/Snackbar';
import Loader from 'ui-component/Loader';

// assets
import CloseIcon from '@mui/icons-material/Close';

// ==============================|| AppStatus ||============================== //

const AppStatus = () => {
    const [showMessage, setShowMessage] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [backdropMessage, setBackdropMessage] = useState();
    const statusData = useSelector((state) => state.status);
    // console.log(statusData);

    const handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowMessage(false);
    };

    useEffect(() => {
        setShowMessage(statusData.showMessage);
        setShowLoader(statusData.isLoading);
        setShowBackdrop(statusData.showBackdrop);
        setBackdropMessage(statusData.backdropMessage);
    }, [statusData]);

    return (
        <>
            {/* alert snackbar */}
            <MuiSnackbar
                TransitionComponent={Fade}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={showMessage}
                autoHideDuration={4000}
                onClose={(e, v) => handleClose(v)}
            >
                <Alert
                    variant="filled"
                    severity={statusData.alertSeverity}
                    sx={{
                        bgcolor: `${statusData.alertSeverity}.dark`,
                        color: statusData.alertSeverity === 'warning' ? 'grey.800' : '#ffffff'
                    }}
                    action={
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleClose()}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                >
                    {statusData.message}
                </Alert>
            </MuiSnackbar>
            {showLoader && <Loader />}
            {showBackdrop && (
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showBackdrop}>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Typography variant="h3" sx={{ color: 'white', pb: 2 }}>
                            {backdropMessage}
                        </Typography>
                        <CircularProgress color="inherit" />
                    </Grid>
                </Backdrop>
            )}
        </>
    );
};

export default AppStatus;
