// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard,  IconUsers, IconCoin, IconMessages, IconChartBar, IconCash } from '@tabler/icons';
import { MULTY_ACCOUNT_ENABLED_FLAG, CLIENT_ACCOUNT_ENABLED_FLAG } from '../config';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
// ==============================|| ADMIN MENU ITEMS ||============================== //

// console.log(MULTI_ENTITY_ACCOUNT_ENABLED)

const multyFlag = MULTY_ACCOUNT_ENABLED_FLAG === 'true'; 
const clientFlag = CLIENT_ACCOUNT_ENABLED_FLAG === 'true';

const admin = {
    id: 'admin-menu',
    title: <FormattedMessage id="admin-dashboard-title" />,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'accounts',
            title: <FormattedMessage id="menu-accounts" />,
            type: multyFlag || clientFlag ? 'item' : 'close',
            url: '/am/accounts',
            icon: IconCoin,
            breadcrumbs: false
        },
        {
            id: 'menu-reports',
            title: <FormattedMessage id="menu-reports" />,
            type: 'item',
            url: '/admin/reports',
            icon: IconChartBar,
            breadcrumbs: false
        },
        {
            id: 'adminstration-page',
            title: <FormattedMessage id="administration-page" />,
            type: 'item',
            url: '/admin/administration',
            icon: AdminPanelSettingsIcon,
            breadcrumbs: false
        },
        /*
        {
            id: 'menu-messages',
            title: <FormattedMessage id="menu-messages" />,
            type: 'item',
            url: '/admin/messages',
            icon: IconMessages,
            breadcrumbs: false
        },
        */
        {
            id: 'clients-page',
            title: <FormattedMessage id="clients" />,
            type: 'item',
            url: '/admin/clients',
            icon: IconUsers,
            breadcrumbs: false
        }
        /*
        {
            id: 'menu-payroll',
            title: <FormattedMessage id="menu-payroll" />,
            type: 'item',
            url: '/admin/payroll',
            icon: IconCash,
            breadcrumbs: false
        },
        */
    ]
};

export default admin;
