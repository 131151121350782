import admin from './admin';
import am from './am';
import client from './client';

// ===========================|| MENU ITEMS ||=========================== //

const menuItems = ({ userType, isSuperUser }) => {
    const items = [];
    // console.log('Menu for', userType);
    if (userType === 'admin') {
        items.push(admin);
        if (isSuperUser) {
            items.push(am);
            items.push(client);
        }
    } else if (userType === 'am') {
        items.push(am);
    } else {
        items.push(client);
    }
    return { items };
};

export default menuItems;
