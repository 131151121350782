import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AMGuard from 'utils/route-guard/AMGuard';
import { MULTY_ACCOUNT_ENABLED_FLAG,CLIENT_ACCOUNT_ENABLED_FLAG } from '../config';
// page routing
const DashboardAM = Loadable(lazy(() => import('views/dashboard/AMDashboard')));
const CalendarPage = Loadable(lazy(() => import('views/calendar')));
const MessagesPage = Loadable(lazy(() => import('views/messages')));
const PayrollPage = Loadable(lazy(() => import('views/payroll')));
const TeamPage = Loadable(lazy(() => import('views/team')));
const UserSettings = Loadable(lazy(() => import('views/user-settings')));
const ClientsPage = Loadable(lazy(() => import('views/clients')));
const ClientDetailPage = Loadable(lazy(() => import('views/clients/ClientDetail')));
const PlayArea = Loadable(lazy(() => import('views/sample-page2')));
const ComingSoon = Loadable(lazy(() => import('views/dashboard/ComingSoon')));
const ReportsPage = Loadable(lazy(() => import('views/reporting')));
const ReportsDetailPage = Loadable(lazy(() => import('views/reporting/ReportDetail')));
const NotFoundPage = Loadable(lazy(() => import('views/common/404')));
const AccountsPage = Loadable(lazy(() => import('views/accounts/Accounts')))
const GroupDetails = Loadable(lazy(() => import('views/group/GroupDetails')));
const FilterReports =Loadable(lazy(()=>import('views/reporting/amReports/FilterReports')))


// ==============================|| MAIN ROUTING ||============================== //
const multyFlag = MULTY_ACCOUNT_ENABLED_FLAG === 'true';
const clientFlag = CLIENT_ACCOUNT_ENABLED_FLAG === 'true';

const MainRoutes = {
    path: '/am',
    element: (
        <AMGuard>
            <MainLayout />
        </AMGuard>
    ),
    children: [
        {
            path: '/am',
            element: <DashboardAM />
        },
        {
            path: '/am/clients',
            element: <ClientsPage />
        },
        {
            path: '/am/client',
            element: <ClientDetailPage />,
            children: [{ path: ':id', element: <ClientDetailPage /> }]
        },
        {
            path: '/am/messages',
            element: <MessagesPage />
        },
        {
            path: '/am/calendar',
            element: <CalendarPage />
        },
        {
            path: '/am/settings',
            element: <UserSettings />
        },
        {
            path: '/am/reports',
            element: <ReportsPage />
        },
        {
            path: '/am/groupdetails',
            element: <GroupDetails amView/>,
            children: [{ path: ':id', element: <GroupDetails amView/> }]
        },
        {
            path: '/am/payroll',
            element: <PayrollPage props={{ pageName: 'Account Manager Payroll Page' }} />
        },
        {
            path: '/am/team',
            element: <TeamPage props={{ pageName: 'Account Manager Team Page' }} />
        },
        {
            path: '/am/report',
            element: <ReportsDetailPage />,
            children: [{ path: ':id', element: <ReportsDetailPage /> }]
        },
        {
            path: '/am/play-area',
            element: <PlayArea />
        },
        {
            path: '/am/report-filter',
            element: <FilterReports />,
            children: [{ path: ':id', element: <FilterReports /> }]
        },
        multyFlag||clientFlag? {
            path: '/am/accounts',
            element: < AccountsPage/>
        }:'',
        {
            path: '*',
            element: <NotFoundPage />
        }
    ]
};

export default MainRoutes;
