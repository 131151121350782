import { 
    createSlice, 
    createAsyncThunk 
} from '@reduxjs/toolkit';

// Import API functions related to connectedApps
import {
    getQuickBookConnectingApps,
    updateConnectingApp
} from 'APIs/qbo';

import appLogger from 'utils/common/appLogger.ts';

// ==============================|| REDUX STORE: CONNECTED APPs DATA ||============================== //

const initialState = {
    initialized: false,
    connectedApps: [],
    error: null
};

const NAME = 'connectedAppSlice()';

const UPDATE_CONNECTED_APP = createAsyncThunk('connectedApps/update', async ({appId, data}) => {

    if (!appId) {
        throw new Error('Missing groupID');
    }
    try {
        const response = await updateConnectingApp(appId, data);
        appLogger.debug(NAME, 'UPDATE_CONNECTED_APP_SUCCESS', response);
        return response;
    } catch (error) {
        appLogger.error(NAME, 'UPDATE_CONNECTED_APP_ERROR', error);
        return null;
    }
});

const GET_DJANGO_CONNECTED_APPS_LIST = createAsyncThunk('connectedApps/list', async (groupId) => {
    const response = await getQuickBookConnectingApps(groupId);
    return response;
});

const DELETE_CONNECTED_APPS = createAsyncThunk('connectedApp/delete', async ({ id }) => {
    // const response = await deleteClientAPI({ id });
    // return response;
});

const connectedAppSlice = createSlice({
    name: 'connectedApp',
    initialState,
    reducers: {
        create(state, action) {
            state.connectedApps.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.connectedApps = [];
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(DELETE_CONNECTED_APPS.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                if (deletedID) {
                    state.connectedApps = state.connectedApps.filter((item) => item.id !== deletedID);
                }
            })
            .addCase(GET_DJANGO_CONNECTED_APPS_LIST.fulfilled, (state, action) => {
                state.connectedApps = [...action.payload];
                state.initialized = true;
            })
            .addCase(UPDATE_CONNECTED_APP.fulfilled, (state, action) => {
                if (action.payload?.id) {
                    state.connectedApps = state.connectedApps.map((item) => {
                        if (item.id === action.payload.id) {
                            return {...item,...action.payload };
                        }
                        return item;
                    });
                }
            })
            
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state) => {
                    state.isLoading = true;
                }
            );
    }
});

const { create, logout } = connectedAppSlice.actions;
const initialized = (state) => state?.connectedApp?.initialized;
const connectedApps = (state) => state?.connectedApp?.connectedApps;

export {

    initialized,
    connectedApps,
    create,
    logout,
    GET_DJANGO_CONNECTED_APPS_LIST,
    DELETE_CONNECTED_APPS,
    UPDATE_CONNECTED_APP,
};
export default connectedAppSlice.reducer;
