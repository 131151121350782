import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    openItem: ['dashboard'],
    drawerOpen: false,
    elevationScrollTrigger: false
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        activeItem(state, action) {
            state.openItem = action.payload;
        },
        logout(state) {
            state.openItem = ['dashboard'];
            state.drawerOpen = false;
            state.elevationScrollTrigger = false;
        },
        openDrawer(state, action) {
            state.drawerOpen = action.payload;
        },
        triggerElevationScroll(state, action) {
            console.log(state, action);
            state.elevationScrollTrigger = action.payload;
        }
    }
});

export default menu.reducer;

export const { activeItem, openDrawer, logout, triggerElevationScroll } = menu.actions;
