import { memo } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const userType = useSelector((state) => state?.account?.user?.type) || 'client';
    const isSuperUser = useSelector((state) => state?.account?.isSuperUser);
    console.log('isSuperUser', isSuperUser);
    console.log('userType', userType);
    const navItems = menuItem({ userType, isSuperUser }).items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
