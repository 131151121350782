import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// project imports
import useAuth from 'hooks/useAuth';
import { ADMIN_DASHBOARD_PATH, ACCOUNT_MANAGER_DASHBOARD_PATH, ONBOARDING_HOME_PATH, CLIENT_DASHBOARD_PATH } from 'config';
import { userClientAPIData } from 'store/slices/accountSlice';
import DataContext from 'contexts/DataContext';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const ClientGuard = ({ children }) => {
    const { isLoggedIn, userType, onboardComplete } = useAuth();
    const navigate = useNavigate();
    console.log('AuthGuard Client: userType', userType);
    const type = userType || 'client';
    const reduxOnboardingData = useSelector((state) => state.onboarding);


    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login', { replace: true });
        }
        // } else if (userType === 'admin') {
        //     navigate(ADMIN_DASHBOARD_PATH, { replace: true });
        // } else if (userType === 'am') {
        //     navigate(ACCOUNT_MANAGER_DASHBOARD_PATH, { replace: true });
        // } else if (userType !== 'admin' && !clientOnboardingComplete) {
        //     navigate('/onboard', { replace: true });
        // }
        // Allow AMs and Admins to view client pages, but redirect non-onboarded clients back to onboarding
        // else if (
        //     userType !== 'am' &&
        //     userType !== 'admin' &&
        //     reduxOnboardingData.accountList?.onboardingList?.length===0
        // ) {
        //     // console.log("Useeffect data context",dataContext)
        //     navigate('/client/accounts', { replace: true });
        // }
        // console.log("Useeffect data context",dataContext)
    }, [isLoggedIn, userType,reduxOnboardingData]);

    return children;
};

ClientGuard.propTypes = {
    children: PropTypes.node
};

export default ClientGuard;
