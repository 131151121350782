import { apiGet, apiFileUpload } from './apiClient';

const getFileURLAPI = async ({ fileName, path, mimeType, fileRef }) => {
    const url = `/file/geturl?name=${fileName}&path=${path}&mimeType=${mimeType}&fileRef=${fileRef}`;
    const response = await apiGet({ url });
    return response;
};

// Needed as Admins need to post to any Org's bucket
const adminGetFileURLAPI = async ({ fileName, path, mimeType, fileRef, orgID }) => {
    const url = `/admin/file/geturl?name=${fileName}&path=${path}&mimeType=${mimeType}&fileRef=${fileRef}&orgID=${orgID}`;
    const response = await apiGet({ url });
    return response;
};

const uploadFileWithURLAPI = async ({ url, file }) => {
    const uploadResponse = await apiFileUpload({ url, file });
    console.log('Upload File:', uploadResponse);
};

export { getFileURLAPI, uploadFileWithURLAPI, adminGetFileURLAPI };
