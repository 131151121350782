// Define log levels (adjust as needed)
const LOG_LEVELS = {
    DEBUG: 0,
    INFO: 1,
    WARN: 2,
    ERROR: 3
};

// Helper function to get current log level from environment
const getLogLevel = () => {
    const envLogLevel = process.env.REACT_APP_LOG_LEVEL || 'INFO'; // Default to INFO
    const level = LOG_LEVELS[envLogLevel.toUpperCase()];
    if (level === undefined) {
        console.warn(`Invalid log level: ${envLogLevel}. Defaulting to 'INFO'`);
        return LOG_LEVELS.INFO;
    }
    return level;
};

// Custom logging function
const appLogger = {
    debug: (...args) => {
        if (getLogLevel() <= LOG_LEVELS.DEBUG) {
            console.log(...args);
        }
    },
    info: (...args) => {
        if (getLogLevel() <= LOG_LEVELS.INFO) {
            console.info(...args);
        }
    },
    warn: (...args) => {
        if (getLogLevel() <= LOG_LEVELS.WARN) {
            console.warn(...args);
        }
    },
    error: (...args) => {
        if (getLogLevel() <= LOG_LEVELS.ERROR) {
            console.error(...args);
        }
    }
};

export default appLogger;
