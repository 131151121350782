import { 
    createSlice, 
    createAsyncThunk 
} from '@reduxjs/toolkit';

import {
    getAdminUserList,
} from 'APIs/admin/userManagement';


import appLogger from 'utils/common/appLogger.ts';

// ==============================|| REDUX STORE: USERS DATA (ADMIN MANAGEMENT) ||============================== //

const initialState = {
    initialized: false,
    manageUserData: null, // object containing pagination information as well as user data
    manageUserList: [], // array of user objects
    error: null
};

const NAME = 'manageUserSlice()';

const GET_MANAGE_USER_LIST = createAsyncThunk('manageUser/list', async (params) => {

    const response = await getAdminUserList(params);
    appLogger.debug(NAME, `GET_MANAGE_USER_LIST >>> PARAMS :: ${params}`, response);
    return response;
});


const manageUserSlice = createSlice({
    name: 'manageUser',
    initialState,
    reducers: {
        create(state, action) {
            state.manageUserList.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.manageUserList = [];
            state.manageUserData = null;
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GET_MANAGE_USER_LIST.fulfilled, (state, action) => {
                state.manageUserData = {...action.payload};
                state.manageUserList = [...action.payload?.items];
                state.initialized = true;
            })
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state) => {
                    state.isLoading = false;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state) => {
                    state.isLoading = true;
                }
            );
    }
});

const { create, logout } = manageUserSlice.actions;
const initialized = (state) => state?.manageUser?.initialized;
const manageUserList = (state) => state?.manageUser?.manageUserList;
const manageUserData = (state) => state?.manageUser?.manageUserData;

export {
    initialized,
    manageUserList,
    manageUserData,
    create,
    logout,
    GET_MANAGE_USER_LIST
};
export default manageUserSlice.reducer;
