import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTaskListAPI, updateTaskAPI, createTaskAPI, deleteTaskAPI } from 'APIs/task';

// ==============================|| REDUX STORE: EVENT DATA ||============================== //

const getTaskList = createAsyncThunk('task/list', async () => {
    const response = await getTaskListAPI();
    return response;
});

const createTask = createAsyncThunk('task/create', async ({ data }) => {
    const response = await createTaskAPI({ data });
    return response;
});

const updateTask = createAsyncThunk('task/update', async ({ id, data }) => {
    const response = await updateTaskAPI({ id, data });
    return response;
});

const deleteTask = createAsyncThunk('task/delete', async ({ id }) => {
    const response = await deleteTaskAPI({ id });
    return response;
});

const initialState = {
    initialized: false,
    tasks: []
};

const taskSlice = createSlice({
    name: 'task',
    initialState,
    reducers: {
        create(state, action) {
            state.tasks.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.tasks = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createTask.fulfilled, (state, action) => {
                state.tasks.push(action.payload);
            })
            .addCase(updateTask.fulfilled, (state, action) => {
                state.tasks = state.tasks.map((task) => {
                    if (task.id === action.payload.id) {
                        return { ...task, ...action.payload };
                    }
                    return task;
                });
            })
            .addCase(deleteTask.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                if (deletedID) {
                    state.tasks = state.tasks.filter((item) => item.id !== deletedID);
                }
            })
            .addCase(getTaskList.fulfilled, (state, action) => {
                state.tasks = [...action.payload];
                state.initialized = true;
            })
            .addMatcher(
                (action) => action.type.includes('title/action/attorney/email/1/fulfilled'),
                (state, action) => {
                    // Nev Task
                    console.log('action-task', action.payload.task);
                    state.tasks.push(action.payload.task);
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/action/attorney/email/2/fulfilled'),
                (state, action) => {
                    // Nev Task
                    state.tasks.push(action.payload.task);
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/action/cndEmail/fulfilled'),
                (state, action) => {
                    // Nev Task
                    state.tasks.push(action.payload.task);
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/action/cnd2Email/fulfilled'),
                (state, action) => {
                    // Nev Task
                    state.tasks.push(action.payload.task);
                }
            );
    }
});

const { logout } = taskSlice.actions;
const initialized = (state) => state?.task?.initialized;
const tasks = (state) => state?.task?.tasks;

export { getTaskList, updateTask, initialized, tasks, createTask, deleteTask, logout };
export default taskSlice.reducer;
