import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    getMessageListAPI,
    updateMessageAPI,
    createMessageAPI,
    deleteMessageAPI,
    getMessageTemplateListAPI,
    getMessageTemplateAPI,
    updateMessageTemplateAPI,
    createMessageTemplateAPI,
    deleteMessageTemplateAPI
} from 'APIs/message';

// ==============================|| REDUX STORE: EVENT DATA ||============================== //

// ------------------- Message template apis -------------------

const GET_MESSAGE_TEMPLATE_LIST = createAsyncThunk('message-template/list', async () => {
    const response = await getMessageTemplateListAPI();
    return response;
});

const GET_MESSAGE_TEMPLATE = createAsyncThunk('message-template/list', async ({ id }) => {
    const response = await getMessageTemplateAPI({ id });
    return response;
});

const CREATE_MESSAGE_TEMPLATE = createAsyncThunk('message-template/create', async ({ data }) => {
    const response = await createMessageTemplateAPI({ data });
    return response;
});

const UPDATE_MESSAGE_TEMPLATE = createAsyncThunk('message-template/update', async ({ id, data }) => {
    const response = await updateMessageTemplateAPI({ id, data });
    return response;
});

const DELETE_MESSAGE_TEMPLATE = createAsyncThunk('message-template/delete', async ({ id }) => {
    const response = await deleteMessageTemplateAPI({ id });
    return response;
});

// ------------------- Message apis -------------------

const getMessageList = createAsyncThunk('message/list', async () => {
    const response = await getMessageListAPI();
    return response;
});
const createMessage = createAsyncThunk('message/create', async ({ data }) => {
    const response = await createMessageAPI({ data });
    return response;
});

const updateMessage = createAsyncThunk('message/update', async ({ id, data }) => {
    const response = await updateMessageAPI({ id, data });
    return response;
});

const deleteMessage = createAsyncThunk('message/delete', async ({ id }) => {
    const response = await deleteMessageAPI({ id });
    return response;
});

const initialState = {
    initialized: false,
    messages: [],
    messageTemplates: []
};

const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        create(state, action) {
            state.messages.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.messages = [];
            state.messageTemplates = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(CREATE_MESSAGE_TEMPLATE.fulfilled, (state, action) => {
                state.messageTemplates.push(action.payload);
            })
            .addCase(UPDATE_MESSAGE_TEMPLATE.fulfilled, (state, action) => {
                state.messageTemplates = state.messageTemplates.map((message) => {
                    if (message.id === action.payload.id) {
                        return { ...message, ...action.payload };
                    }
                    return message;
                });
            })
            .addCase(DELETE_MESSAGE_TEMPLATE.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                if (deletedID) {
                    const list = state.messageTemplates.filter((item) => item.id !== deletedID);
                    if ( list.length > 0 ) {
                        state.messageTemplates = list;
                    } else {
                        state.messageTemplates = [];
                    }
                }
            })
            .addCase(GET_MESSAGE_TEMPLATE_LIST.fulfilled, (state, action) => {
                state.messageTemplates = action.payload;
                state.initialized = true;
            })
            .addCase(createMessage.fulfilled, (state, action) => {
                state.messages.push(action.payload);
            })
            .addCase(updateMessage.fulfilled, (state, action) => {
                state.messages = state.messages.map((message) => {
                    if (message.id === action.payload.id) {
                        return { ...message, ...action.payload };
                    }
                    return message;
                });
            })
            .addCase(deleteMessage.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                if (deletedID) {
                    state.messages = state.messages.filter((item) => item.id !== deletedID);
                }
            })
            .addCase(getMessageList.fulfilled, (state, action) => {
                state.messages = [...action.payload];
                state.initialized = true;
            })
            .addMatcher(
                (action) => action.type.includes('title/action/attorney/email/1/fulfilled'),
                (state, action) => {
                    // Nev Message
                    console.log('action-message', action.payload.message);
                    state.messages.push(action.payload.message);
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/action/attorney/email/2/fulfilled'),
                (state, action) => {
                    // Nev Message
                    state.messages.push(action.payload.message);
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/action/cndEmail/fulfilled'),
                (state, action) => {
                    // Nev Message
                    state.messages.push(action.payload.message);
                }
            )
            .addMatcher(
                (action) => action.type.includes('title/action/cnd2Email/fulfilled'),
                (state, action) => {
                    // Nev Message
                    state.messages.push(action.payload.message);
                }
            );
    }
});

const { logout } = messageSlice.actions;
const initialized = (state) => state?.message?.initialized;
const messages = (state) => state?.message?.messages;
const messageTemplates = (state) => state?.message?.messageTemplates;

export {
    GET_MESSAGE_TEMPLATE_LIST,
    GET_MESSAGE_TEMPLATE,
    CREATE_MESSAGE_TEMPLATE,
    UPDATE_MESSAGE_TEMPLATE,
    DELETE_MESSAGE_TEMPLATE,
    messageTemplates,
    getMessageList,
    updateMessage,
    initialized,
    messages,
    createMessage,
    deleteMessage,
    logout
};
export default messageSlice.reducer;
