/* eslint-disable */

import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import useAuth from 'hooks/useAuth';
import { userData, GET_USER, LOGOUT, setLocalSuperuser, updateLocalUser } from 'store/slices/accountSlice';
import { userTypes } from 'config';

// assets
import { IconLogout, IconSettings, IconArrowsUpDown, IconRecycle } from '@tabler/icons';
import useConfig from 'hooks/useConfig';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';

const getUserName = (user) => {
    const { givenName, name = 'unknown' } = user;
    return givenName || name;
};

const getUserType = (user) => {
    if (!user || !user.type) {
        return 'Unknown';
    }
    return user.type.charAt(0).toUpperCase() + user.type.slice(1);
};

// const getUserEmail = (user) => {
//     if (!user || !user.email) {
//         return 'Unknown';
//     }
//     return user.email;
// };

// const isSuperUser = (user) => getUserEmail(user) === 'max@getmyfixe.com';
// const isSuperUserAllowed = (user) => user.isSuperUser || getUserEmail(user) === 'max@getmyfixe.com';

const getGreeting = () => {
    const hours = new Date().getHours();
    if (hours < 12) {
        return 'Good Morning';
    }
    if (hours < 18) {
        return 'Good Afternoon';
    }
    return 'Good Evening';
};

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { borderRadius } = useConfig();
    const isInitialized = useSelector((state) => state?.account?.isInitialized);
    const isLoggedIn = useSelector((state) => state?.account?.isLoggedIn);
    const isLoading = useSelector((state) => state?.account?.isLoading);
    const userRecord = useSelector((state) => state?.account?.user); // useSelector(userData) || {};
    const isSuperUser = useSelector((state) => state?.account?.isSuperUser);
    // const isSuperUserAllowed = useSelector((state) => state?.account?.user?.type === 'max@getmyfixe.com');

    const verified_superusers = ['max@getmyfixe.com'];
    const isVerifiedSuperUser = useSelector((state) => verified_superusers.includes(state?.account?.user?.email));

    const { logout, userType } = useAuth();
    const isSuperUserAllowed = userType === 'admin' || isVerifiedSuperUser;
    const [open, setOpen] = useState(false);
    const [roleOptionsOpen, setRoleOptionsOpen] = useState(false);

    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleSettings = (event) => {
        handleClose(event);

        const userType = userRecord?.type || '';
        const settingsURL = `/${userType}/settings`;
        navigate(settingsURL);
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        if (isLoggedIn) {
            // debugger; // eslint-disable-line no-debugger
            if (!isInitialized && !isLoading) {
                console.log('>>>Initialize user');
                // debugger; // eslint-disable-line no-debugger
                dispatch(GET_USER());
            }
        }
    }, [isInitialized, isLoggedIn, dispatch]);

    const handleClickSuperuser = () => {
        setRoleOptionsOpen(!roleOptionsOpen);

        const newIsSuperUser = !isSuperUser;
        dispatch(setLocalSuperuser(newIsSuperUser));
    };

    const handleClickResetOnboarding = () => {
        console.log('Resetting onboarding is disabled for now.');
        // const data = { onboardComplete: false, onboardingAccountInfoComplete: false };
        // dispatch(updateLocalUser({ data }));
    };

    const handleChangeUserType = (newRole) => {
        const data = { type: newRole };
        dispatch(updateLocalUser(data));
    };

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.main,
                        background: `${theme.palette.primary.main}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        src={userRecord?.photoURL}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={<Typography>{userRecord?.name || 'unknown'}</Typography>}
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />

            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Box sx={{ p: 2, pb: 0 }}>
                                            <Stack>
                                                <Stack direction="row" spacing={0.5} alignItems="center">
                                                    <Typography variant="h4">{getGreeting()}, </Typography>
                                                    <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                                        {getUserName(userRecord)}
                                                    </Typography>
                                                </Stack>
                                                <Typography variant="subtitle2">{getUserType(userRecord)}</Typography>
                                            </Stack>
                                            <Divider />
                                        </Box>
                                        {/* <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}> */}
                                        <Box sx={{ p: 2, pt: 0 }}>
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                <ListItemButton
                                                    sx={{ borderRadius: `${borderRadius}px` }}
                                                    onClick={(event) => handleSettings(event)}
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                                                </ListItemButton>
                                                <ListItemButton sx={{ borderRadius: `${borderRadius}px` }} onClick={handleLogout}>
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                                </ListItemButton>
                                                {isSuperUserAllowed && (
                                                    <>
                                                        <ListItemButton
                                                            sx={{ borderRadius: `${borderRadius}px` }}
                                                            onClick={handleClickSuperuser}
                                                        >
                                                            <ListItemIcon>
                                                                <IconArrowsUpDown stroke={1.5} size="1.3rem" />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={<Typography variant="body2">Toggle Superuser</Typography>}
                                                            />
                                                            <KeyboardArrowDown
                                                                sx={{
                                                                    mr: -1,
                                                                    transform: roleOptionsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                                                    transition: '0.2s'
                                                                }}
                                                            />
                                                        </ListItemButton>

                                                        {/* {roleOptionsOpen &&
                                                            userTypes.map((userType) => (
                                                                <ListItemButton
                                                                    key={userType.id}
                                                                    sx={{ ml: 4, py: 0, minHeight: 32, color: 'rgba(255,255,255,.8)' }}
                                                                    onClick={() => handleChangeUserType(userType.id)}
                                                                >
                                                                <ListItemText
                                                                    primary={userType.name}
                                                                    // primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                                                                />
                                                            </ListItemButton>
                                                            ))
                                                        } */}

                                                        <ListItemButton
                                                            sx={{ borderRadius: `${borderRadius}px` }}
                                                            onClick={handleClickResetOnboarding}
                                                        >
                                                            <ListItemIcon>
                                                                <IconRecycle stroke={1.5} size="1.3rem" />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={<Typography variant="body2">Reset Onboarding</Typography>}
                                                            />
                                                        </ListItemButton>
                                                    </>
                                                )}
                                            </List>
                                        </Box>
                                        {/* </PerfectScrollbar> */}
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
