const GROUPS_API_BASE_URL = '/groups/';

const endpoints = {
    groups: `${GROUPS_API_BASE_URL}`,
    groupContacts: (groupId) => `${GROUPS_API_BASE_URL}${groupId}/groupcontacts/`,
    locationContacts: (groupId) => `${GROUPS_API_BASE_URL}${groupId}/locationcontacts/`,
    groupOnboarding: (groupId) => `${GROUPS_API_BASE_URL}${groupId}/onboarding/`
};

export default endpoints;
