// material-ui
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import {  userData } from 'store/slices/accountSlice';

import { useLocation } from 'react-router-dom';

import MainCard from 'ui-component/cards/MainCard';
import { postQuickBookExchangeCode } from '../../APIs/qbo';
import Box from '@mui/material/Box';

import { initialized as connectedAppInitialized, connectedApps, GET_DJANGO_CONNECTED_APPS_LIST } from 'store/slices/connectedAppSlice';


// ==============================|| ACCOUNTING PAGE ||============================== //

const QuickBookConnect = () => {

    const userRecord = useSelector(userData) || {};
    const location = useLocation();
    const dispatch = useDispatch();
    const searchParams = new URLSearchParams(location.search);

    const [data, setData] = useState({
        loading: false,
        error: null,
        completed: false,
    })

    const exchangeCode = async () => {
        await new Promise(r => setTimeout(r, 2000));
        setData({ loading: true, error: null, completed: false });
        const quickBook = localStorage.getItem('quick_book');
        if (!quickBook) {
            setData({ loading: false, error: 'Quick-Book data not found in application. Please try again later!! ', completed: false });
            return;
        }

        const quickBookData = JSON.parse(quickBook);

        if (!searchParams.get('code') || !searchParams.get('state') || !searchParams.get('realmId') ) {
            setData({ loading: false, 
                error: 'Unable to connect with Quick-book. Please try again Later!!. Make sure you allow the permissions.', 
                completed: false });
            return;
        }

        if (searchParams.get('state') !== quickBookData.state) {
            setData({ loading: false, error: 'State mismatch Error!!  Please try again Later!! ', completed: false });
            return;
        }

        const data = {
            code: searchParams.get('code'),
            realmId: searchParams.get('realmId'),
            groupId: quickBookData.group,
            scope: quickBookData.scope,
            state: quickBookData.state,
            username: userRecord.email,
            userid: userRecord.userName
        }
        try {
            await postQuickBookExchangeCode({"data": data});
            dispatch(GET_DJANGO_CONNECTED_APPS_LIST());
            localStorage.removeItem('quick_book');

        } catch (error) {
            console.log('error: ', error);
            setData({ loading: false, error: error.message ? error.message : 'Unable to connect with Quick-book. Please try again Later!! ', completed: false });
            return;
        }

        setData({ loading: false, error: null, completed: true });

        setTimeout(() => {
            // Navigate to quick-book connection page
            window.location.href = `/am/groupdetails/${quickBookData.group}`; 
        }, 3000);
    }

    useEffect(() => {
        exchangeCode();
    },[]);

    const displayMsg = () => {
        if (data.loading === true) {
            return (<Box>
                We&apos;re connecting your Quick-Book account to our platform. 
                Please be patient, as this process can take a few seconds.
                Once we have connected your account, you&apos;ll be able to start using our services.
               </Box>)
        } 
        if((data.loading === false) && (data.error != null)) {
            return (<Box sx={{ color: 'error.main'} }>
                {data.error}
            </Box>)
        }  
        if ((data.loading  === false) && (data.completed === true)) {
            return (<Box sx={{ color: 'success.main'} }>
                Connection completed successfully. You can now start using our services.
                <br/>
                You&apos;ll be redirected to your group details page shortly.  Please be patient.
            </Box>)
        }
        return (<Box>
            We&apos;re connecting your Quick-Book account to our platform. 
            Please be patient, as this process can take a few seconds.
            Once we have connected your account, you&apos;ll be able to start using our services.
        </Box>)
        
    }

    return (<MainCard title="Quick-Book Connection Page">
        <Typography variant="body2">
            {displayMsg()}
        </Typography>
    </MainCard>)
};

export default QuickBookConnect;