import { apiUpdate, apiDelete, apiPostDjango, apiDjangoGet } from './apiClient';

///---------------------------------------------------------------------------------------
// APIs for Integrations List
//

const createIntegrationAPI = async ({ data }) => {
    const response = await apiPostDjango({ url: '/administration/integrations/', data });
    return response;
};

const getIntegrationsAPI = async (params=null) => apiDjangoGet({ url: `/administration/integrations/`, params });

const updateIntegrationAPI = async ({ id, data }) => {
    const response = await apiUpdate({ url: `/administration/integrations/${id}/`, data });
    return response;
};
const deleteIntegrationAPI = async ({ id }) => {
    const response = await apiDelete({ url: `/administration/integrations/${id}/` });
    return response;
};

const getAutoCompleteIntegrationsListAPI = async (params = null) => 
    apiDjangoGet({ url: `/administration/integrations/autoComplete-list-integrations/`, params});

const getIntegrationsList4TypeAPI = async (params = null) => 
    apiDjangoGet({ url: `/administration/integrations/`, params});


///---------------------------------------------------------------------------------------
// APIs for Plans List
//

const createPlanAPI = async ({ data }) => {
    const response = await apiPostDjango({ url: '/plans/', data });
    return response;
};

const getPlansAPI = async () => apiDjangoGet({ url: `/plans/` });

const updatePlanAPI = async ({ id, data }) => {
    const response = await apiUpdate({ url: `/plans/${id}/`, data });
    return response;
};
const deletePlanAPI = async ({ id }) => {
    const response = await apiDelete({ url: `/plans/${id}/` });
    return response;
};



export {
    createIntegrationAPI,
    getIntegrationsAPI,
    updateIntegrationAPI,
    deleteIntegrationAPI,
    createPlanAPI,
    getPlansAPI,
    updatePlanAPI,
    deletePlanAPI,
    getAutoCompleteIntegrationsListAPI,
    getIntegrationsList4TypeAPI
};
