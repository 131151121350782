import { lazy } from 'react';

// project imports
import OnboardLayout from 'layout/OnboardLayout';
import Loadable from 'ui-component/Loadable';
import ClientGuard from 'utils/route-guard/ClientGuard';
import ProtectedRoute from 'utils/protected-route';

// onboarding page routing
const OnboardingHome = Loadable(lazy(() => import('views/onboading')));
const OnboardingTasks = Loadable(lazy(() => import('views/onboading/tasks/OnboardingTasks')));
const OnboardingOperationsHome = Loadable(lazy(() => import('views/onboading/operations')));
const ContractPage = Loadable(lazy(()=> import('views/onboading/contract/ContractPage')))

const Completion = Loadable(lazy(() => import('views/group/payment/Complete')));
const PaymentPage = Loadable(lazy(() => import('views/group/payment/Payment')));

// ==============================|| MAIN ROUTING ||============================== //

const OnboardingRoutes = {
    path: '/onboard',
    element: (
        <ProtectedRoute>
            <ClientGuard>
                <OnboardLayout />
            </ClientGuard>
        </ProtectedRoute>
    ),
    children: [
        {
            path: '/onboard',
            element: <OnboardingHome />
        },
        {
            path: '/onboard/account-info',
            element: <OnboardingTasks />
            // children: [{ path: ':id', element: <OnboardingTasks /> }]
            // children: [{ path: ':id', element: <OnboardingTasks /> }]
        },
        {
            path: '/onboard/operations',
            element: <OnboardingOperationsHome />
        },
        {
            path: '/onboard/group/:id/payment',
            element: <PaymentPage props={{ pageName: 'Payment Checkout Page' }}/>,
        },
        {
            path: '/onboard/payment/complete',
            element: <Completion props={{ pageName: 'Checkout Completion Page' }} />
        },
    ]
};

export default OnboardingRoutes;
