import { createSlice } from '@reduxjs/toolkit';

const onBoardingSlice = createSlice({
    name: 'onBoarding',
    initialState: {
        accountList: [], // Use an empty array as a default to avoid null checks
        contractSignatureRequired: false,
        paymentRequired: false,
        group: {}, // Use an empty object as a default
        locations: [],
        skipAllowed: false
    },
    reducers: {
        setAccountList: (state, action) => {
            state.accountList = action.payload;
        },
        setContractSignatureRequired: (state, action) => {
            state.contractSignatureRequired = action.payload;
        },
        setPaymentRequired: (state, action) => {
            state.paymentRequired = action.payload;
        },
        setSkipAllowed: (state, action) => {
            state.skipAllowed = action.payload;
        },
        resetOnboardingState: () => ({
            accountList: [],
            contractSignatureRequired: false,
            paymentRequired: false,
            group: {},
            locations: [],
            skipAllowed: false
        })
    }
});

export const {
    setAccountList,
    setContractSignatureRequired,
    setPaymentRequired,
    setSkipAllowed,
    resetOnboardingState
} = onBoardingSlice.actions;

export default onBoardingSlice.reducer;
