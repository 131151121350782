import { apiUpdate, apiDelete, apiPostDjango, apiDjangoGet } from './apiClient';

//---------------------------------------------------------------------------------------
// APIs for Employees
//
const createEmployeeAPI = async ({ data }) => {
    const response = await apiPostDjango({ url: '/administration/employees/', data });
    return response;
};
const getEmployeeByEmailAPI = async ({ email }) => {
    const response = await apiDjangoGet({ url: `/administration/employees/email/${email}/` });
    return response;
};
const getDirectReportsAPI = async ({ id }) => {
    const response = await apiDjangoGet({ url: `/administration/employees/${id}/` });
    return response;
};
const getEmployeesAPI = async (params='all') => apiDjangoGet({ url: `/administration/employees/`, params});
const updateEmployeeAPI = async ({ id, data }) => {
    const response = await apiUpdate({ url: `/administration/employees/${id}/`, data });
    return response;
};
const deleteEmployeeAPI = async ({ id }) => {
    const response = await apiDelete({ url: `/administration/employees/${id}/` });
    return response;
};
const getAutoCompleteEmployeesListAPI = async (params=null) => apiDjangoGet({ url: '/administration/employees/autoComplete-list-employees/', params});

//---------------------------------------------------------------------------------------
// APIs for Department
//
const createDepartmentAPI = async ({ data }) => {
    const response = await apiPostDjango({ url: '/administration/departments/', data });
    return response;
};
const getDepartmentsAPI = async () => apiDjangoGet({ url: `/administration/departments/` });
const updateDepartmentAPI = async ({ id, data }) => {
    const response = await apiUpdate({ url: `/administration/departments/${id}/`, data });
    return response;
};
const deleteDepartmentAPI = async ({ id }) => {
    const response = await apiDelete({ url: `/administration/departments/${id}/` });
    return response;
};
const getAutoCompleteDepartmentListAPI = async (params=null) => apiDjangoGet({ url: '/administration/departments/autoComplete-list-departments/', params});

//---------------------------------------------------------------------------------------
// APIs for Group Employees
//
const createGroupEmployeesAPI = async ({ data }) => {
    const response = await apiPostDjango({ url: '/administration/groupemployees/', data });
    return response;
};
const getGroupEmployeesAllAPI = async () => apiDjangoGet({ url: `/administration/groupemployees/` });
const getGroupEmployeesAPI = async ({id}) => apiDjangoGet({ url: `/administration/groupemployees/${id}` });
const getGroupEmployeesByGroupAPI = async ({id}) => apiDjangoGet({ url: `/administration/groupemployees/group/${id}` });
const updateGroupEmployeesAPI = async ({ id, data }) => {
    const response = await apiUpdate({ url: `/administration/groupemployees/${id}/`, data });
    return response;
};
const deleteGroupEmployeesAPI = async ({ id }) => {
    const response = await apiDelete({ url: `/administration/groupemployees/${id}/` });
    return response;
};


export {
    createEmployeeAPI,
    getEmployeesAPI,
    getEmployeeByEmailAPI,
    updateEmployeeAPI,
    deleteEmployeeAPI,
    getDirectReportsAPI,
    getAutoCompleteEmployeesListAPI,
    createDepartmentAPI,
    getDepartmentsAPI,
    updateDepartmentAPI,
    deleteDepartmentAPI,
    getAutoCompleteDepartmentListAPI,
    createGroupEmployeesAPI,
    getGroupEmployeesAPI,
    getGroupEmployeesAllAPI,
    getGroupEmployeesByGroupAPI,
    updateGroupEmployeesAPI,
    deleteGroupEmployeesAPI,
};
