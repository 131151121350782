import { apiGet, awsApiUpdate } from './apiClient';

// Task Requests
const getTaskListAPI = async () => apiGet({ url: `/task/list` });

const getTaskAPI = async ({ taskID }) => apiGet({ url: `/task?id=${taskID}` });

const updateTaskAPI = async ({ id, data }) => awsApiUpdate({ url: `/task?id=${id}`, data });

const createTaskAPI = async ({ data }) => undefined;
// const url = `${DOMAIN}task`;
// const response = await apiPost({ method: 'POST', url, body });
//     return undefined;
// };

const deleteTaskAPI = async ({ id }) => undefined;
// const url = `${DOMAIN}task?id=${id}`;
// const response = await apiFetch({ method: 'DELETE', url });
//     return undefined;
// };

export { getTaskListAPI, getTaskAPI, updateTaskAPI, createTaskAPI, deleteTaskAPI };
