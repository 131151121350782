import { apiGet, awsApiUpdate, apiDjangoGet, apiPostDjango, apiUpdate, apiDelete } from './apiClient';

// Message Requests
const getMessageTemplateListAPI = async ( ) => apiDjangoGet({ url: `/messaging/message-template/` });

const getMessageTemplateAPI = async ({ id }) => apiDjangoGet({ url: `/messaging/message-template?id=${id}/` });

const createMessageTemplateAPI = async ({ data }) => apiPostDjango({ url: `/messaging/message-template/`, data });

const updateMessageTemplateAPI = async ({ id, data }) => apiUpdate({ url: `/messaging/message-template/${id}/`, data });

const deleteMessageTemplateAPI = async ({ id }) => apiDelete({ url: `/messaging/message-template/${id}/` });


// Message Requests
const getMessageListAPI = async () => apiGet({ url: `/message/list/` });

const getMessageAPI = async ({ messageID }) => apiGet({ url: `/message?id=${messageID}` });

const updateMessageAPI = async ({ id, data }) => awsApiUpdate({ url: `/message?id=${id}`, data });

const createMessageAPI = async ({ data }) => undefined;
// const url = `${DOMAIN}message`;
// const response = await apiPost({ method: 'POST', url, body });
//     return undefined;
// };

const deleteMessageAPI = async ({ id }) => undefined;
// const url = `${DOMAIN}message?id=${id}`;
// const response = await apiFetch({ method: 'DELETE', url });
//     return undefined;
// };

export { 
    getMessageTemplateListAPI, 
    getMessageTemplateAPI, 
    updateMessageTemplateAPI, 
    createMessageTemplateAPI, 
    deleteMessageTemplateAPI ,
    getMessageListAPI, 
    getMessageAPI, 
    updateMessageAPI, 
    createMessageAPI, 
    deleteMessageAPI 
};
