import React, { useState } from "react";

const DataContext = React.createContext({
  accountList: null,
  contractSignatureRequired: false,
  paymentRequired: false,
  group: null,
  locations: [],
  skipAllowed: false
});

export const DataContextProvider = ({ children }) => {
  const [accountList, setAccountList] = useState(null);
  const [contractSignatureRequired, setContractSignatureRequired] = useState(false);
  const [paymentRequired, setPaymentRequired] = useState(false);
  const [group, setGroup] = useState(null);
  const [locations, setLocations] = useState([]);
  const [skipAllowed, setSkipAllowed] = useState(false);
  const contextValue = {
    accountList,
    contractSignatureRequired,
    paymentRequired,
    group,
    locations,
    skipAllowed,
  };

  return (
      <DataContext.Provider value={contextValue}>
        {children}
      </DataContext.Provider>
    );
};

export default DataContext;

