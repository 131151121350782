/* eslint-disable */
import appLogger from 'utils/common/appLogger.ts';
import { apiGet, awsApiUpdate, apiUpdate, apiDelete, apiPostDjango, apiDjangoGet } from 'APIs/apiClient';

//---------------------------------------------------------------------------------------
// APIs for user-list for user-management purposes
const getAdminUserList = async (params=null) => apiDjangoGet({ url: '/administration/list-users/', params});

//---------------------------------------------------------------------------------------
// APIs for user-list for autocomplete
const getAutoCompleteUserList = async (params=null) => apiDjangoGet({ url: '/administration/autoComplete-list-users/', params});

//---------------------------------------------------------------------------------------
// APIs for Sync the user list form aws cognito
const syncUserWithAWSCognito = async () => apiPostDjango({ url: '/administration/sync-cognito-users/'});


//---------------------------------------------------------------------------------------
// APIs for assigning the group to a user
const assignGroupToCognitoUser = async ({data}) => apiPostDjango({ url: '/administration/manage-user-groups/', data});


//---------------------------------------------------------------------------------------
// APIs for sending the verification code a user
const sendVerificationCode = async ({data}) => apiPostDjango({ url: '/administration/send-verification-code/', data});


//---------------------------------------------------------------------------------------
// APIs for force verification a user
const confirmAndVerifyUser = async ({data}) => apiPostDjango({ url: '/administration/confirm-and-verify-email/', data});


//---------------------------------------------------------------------------------------
// APIs for disable the user
const disableTheUser = async ({data}) => apiDelete({ url: '/administration/delete-or-disable-user/', data});

const addDepartment=async(data)=>{
    const response = await apiPostDjango({ url: 'administration/departments/',data});
    return response;
}
const getDepartments=async()=>{
    const response = await apiDjangoGet({ url: 'administration/departments/'});
    return response;
}

const deleteDepartment=async(id)=>{
    const response = await apiDelete({ url: `administration/departments/${id}`});
    return response;
}

const editDepartment=async(data)=>{
  const response = await apiUpdate({ url: `administration/departments/${data.id}/`, data});
  return response;
}

export {
    getAdminUserList,
    syncUserWithAWSCognito,
    assignGroupToCognitoUser,
    getAutoCompleteUserList,
    sendVerificationCode,
    confirmAndVerifyUser,
    disableTheUser,
    addDepartment,
    deleteDepartment,
    getDepartments,
    editDepartment
}
