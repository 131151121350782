/* eslint-disable */
import appLogger from '../utils/common/appLogger.ts';
import { apiUpdate,  apiPostDjango, apiDjangoGet, apiDjangoDelete } from './apiClient';

//---------------------------------------------------------------------------------------
// APIs for Quick-book connections
const getQuickBookAuthorizationLink = async () => apiDjangoGet({ url: '/qbo/auth/' });


//---------------------------------------------------------------------------------------
// APIs for Quick-book exchange-code for access_token and refresh_token
const postQuickBookExchangeCode = async ({data}) => apiPostDjango({ url: '/qbo/exchange-code/', data });



//---------------------------------------------------------------------------------------
// APIs for Connecting QuickBooks Apps
const getQuickBookConnectingApps = async (groupId) =>{ 
    const params = { group_id: groupId };
    return apiDjangoGet({ url: `/connected-apps/`, params })
};


//---------------------------------------------------------------------------------------
// APIs for Connecting QuickBooks App Locations
const getQuickBookConnectingAppLocations = async (groupId, connectedAppId, params) => apiDjangoGet({ url: `/connected-apps/${groupId}/${connectedAppId}/locations/`, params });


//---------------------------------------------------------------------------------------
// APIs for update the Connecting App
const updateConnectingApp = async (connectedAppId, data) => apiUpdate({ url: `/connected-apps/${connectedAppId}/`,data: data });


//---------------------------------------------------------------------------------------
// APIs for update the Connecting App
const deleteConnectingApp = async (connectedAppId ) => apiDjangoDelete({ url: `/connected-apps/${connectedAppId}/` });


//---------------------------------------------------------------------------------------
// APIs for saving the Connecting App Locations
const postConnectingAppLocations = async (groupId, connectedAppId, data) => apiUpdate({ url: `/connected-apps/${groupId}/${connectedAppId}/locations/`,data: data });


export {
    getQuickBookAuthorizationLink,
    postQuickBookExchangeCode,
    getQuickBookConnectingApps,
    getQuickBookConnectingAppLocations,
    postConnectingAppLocations,
    updateConnectingApp,
    deleteConnectingApp
}
